<template>
  <div>
    <el-form inline class="mb-20">
      <el-form-item label="话题">
        <el-select placeholder="请选择话题" v-model="form.topic_id">
          <el-option label="全部" value=""/>
          <template v-for="(item, index) in classfiy_list" :key="index">
            <el-option :label="item.title" :value="item.id"/>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="关键字搜索">
        <el-input placeholder="请输入关键字搜索" v-model="form.search"/>
      </el-form-item>
      <el-form-item label="帖子状态">
        <el-select placeholder="请选择帖子状态" v-model="form.article_status">
          <el-option label="全部" value=""/>
          <el-option label="正常" :value="10"/>
          <el-option label="被举报" :value="20"/>
          <el-option label="封禁" :value="30"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="right-box">
      <el-button type="primary" @click="toDetail('')">添加话题贴</el-button>
    </div>
    <el-table class="mb-20" stripe border :data="list">
      <el-table-column align="center" prop="article_id" label="ID"/>
      <el-table-column align="center" prop="content" label="内容"/>
      <el-table-column align="center" prop="topic_title" label="标签"/>
<!--      <el-table-column align="center" prop="author" label="发布人"/>-->
      <el-table-column align="center" prop="create_time" label="发布时间"/>

      <el-table-column align="center" prop="article_status.text" label="帖子状态"/>
<!--      <el-table-column align="center" prop="state" label="帖子状态"/>-->
      <el-table-column align="center" label="操作" width="220">
        <template #default="{row}">
          <el-button type="text" @click="showDetailBox(row)">查看详情</el-button>
<!--          <el-divider direction="vertical"></el-divider>-->
<!--          <el-popconfirm title="确定删除?" confirmButtonText="删除" cancelButtonText="取消" @confirm="del(row)">-->
<!--            <template #reference>-->
<!--              <el-button type="text">取消置顶</el-button>-->
<!--            </template>-->
<!--          </el-popconfirm>-->
          <template v-if="row.is_admin">
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="toDetail(row.article_id)">编辑</el-button>
          </template>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="确定删除?" confirmButtonText="删除" cancelButtonText="取消" @confirm="del(row)">
            <template #reference>
              <el-button type="text">删除</el-button>
            </template>
          </el-popconfirm>
<!--          <el-button type="text">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
      <el-col span="1">
        <el-pagination
          :page="params.page"
          :total="total"
          :current-page="params.page"
          @current-change="changePage"
          layout="prev, pager, next"
          background
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      v-model="detailBox"
      title="贴子详情"
      width="700px"
      top="5vh"
    >
      <el-descriptions border :column="1">
        <el-descriptions-item label="内容">{{detail.content}}</el-descriptions-item>
        <el-descriptions-item label="标签">{{detail.topic_title}}</el-descriptions-item>
<!--        <el-descriptions-item label="内容">{{detail.content}}</el-descriptions-item>-->
        <el-descriptions-item label="照片">
          <el-image
            v-for="(item, index) in detail.file"
            :key="index"
            style="width: 100px;height: 100px;margin-right: 10px;"
            :src="globalData.cos_domain + (item.thumbnail || item.url)"
            :preview-src-list="[globalData.cos_domain + (item.thumbnail || item.url)]"
          />
        </el-descriptions-item>
        <el-descriptions-item label="发布人">{{detail.nickName}}</el-descriptions-item>
        <el-descriptions-item label="发布时间">{{detail.create_time}}</el-descriptions-item>
        <el-descriptions-item label="帖子状态">{{detail.article_status.text}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <div>
          <el-button @click="hideDetailBox">关闭</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {ElMessage} from "element-plus";

export default {
  name: 'Social',
  data() {
    return {
      params: {
        page: 1,
        per_page: 20,
      },
      total: 1,
      list: [],
      detailBox: false,
      detail: null,

      form: {
        topic_id: '',
        search: '',
        article_status: ''
      },
    };
  },
  computed: {
    ...mapState(['globalData'])
  },
  created() {
    this.getList(1)
    this.$api.articleTalkTopic.list().then(res => {
      this.classfiy_list = res.data
    })
  },
  methods: {
    showDetailBox(data) {
      this.detail = data;
      this.detailBox = true;
    },
    hideDetailBox() {
      this.detailBox = false;
    },
    getList(page) {
      let that = this;
      that.params.load = true
      this.$api.articleTalk.page({
        ...this.form,
        page: page || this.params.page,
      }).then((res) => {
        // console.log(res);
        that.list = res.data.data;
        that.total = res.data.total;
        that.params.per_page = res.data.per_page;
        that.params.page = (page || this.params.page);
      });
    },
    submit() {
      this.getList(1)
    },
    reset() {
      this.form = {
        topic_id: '',
        search: '',
        article_status: 10
      }
      this.submit()
    },
    changePage(page) {
      this.getList(page)
    },
    del(data) {
      let that = this;
      this.$api.article.delete({
        article_id: data.article_id,
      }).then(() =>{
        ElMessage({
          message: '操作',
          type: 'success',
        })
        that.getList()
      })
    },
    toDetail(id) {
      this.$router.push('/social-detail?id=' + id)
    }
  },
};
</script>

<style scoped>
:deep(.el-descriptions__label) {
  min-width: 90px;
}
</style>
